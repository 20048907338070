import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layouts/layout"
import * as myModule from "../components/dynamic"
import Seo from "../components/Seo/seo"
function index({ data }) {
  const wpData = data.wpPage.sections.blocks
  const seo = data?.wpPage?.seo
  return (
    <Layout>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        featuredImage={
          seo.opengraphImage?.localFile.childImageSharp.gatsbyImageData
        }
      />
      <div id="meet-hixon-page">
        {wpData.map(row => {
          // assign component math with name
          const Component = myModule[row.fieldGroupName.split("_").slice(-1)[0]]
          return <Component key={row.fieldGroupName} {...row} />
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    wpPage(id: { eq: "cG9zdDoyODM=" }) {
      seo {
        ...SeoData
      }
      sections {
        blocks {
          ... on WpPage_Sections_Blocks_PageHeader {
            fieldGroupName
            title
            subtitle
            box {
              title
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPage_Sections_Blocks_Testimonials {
            fieldGroupName
            testimonials {
              ... on WpTestimonial {
                content
                id
              }
            }
            testimonialsColumn2 {
              ... on WpTestimonial {
                content
                id
              }
            }
          }
          ... on WpPage_Sections_Blocks_ConnectedBoxes {
            fieldGroupName
            invertColors
            left {
              title
              sub
              content
              logo {
                sourceUrl
              }
              buttons {
                link {
                  target
                  title
                  url
                }
              }
            }
            right {
              title
              content
            }
            image1 {
              desktop: localFile {
                childImageSharp {
                  gatsbyImageData(height: 482)
                }
              }
              mobile: localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            image2 {
              mobile: localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
              desktop: localFile {
                childImageSharp {
                  gatsbyImageData(height: 482, layout: FIXED)
                }
              }
            }
          }
          ... on WpPage_Sections_Blocks_TwoImages {
            fieldGroupName
            image1 {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 584
                    height: 482
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            image2 {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1326
                    height: 482
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          ... on WpPage_Sections_Blocks_Values {
            fieldGroupName
            title
            content
            layout
            button {
              title
              url
              target
            }
            value {
              title
              content
            }
          }
          ... on WpPage_Sections_Blocks_ApplyNow {
            fieldGroupName
            title
            officers {
              ... on WpLoanOfficer {
                title
                featuredImage {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
                officers {
                  position
                  nmls
                  hixonLendingNmls
                  licensedIn
                  phone {
                    title
                  }
                  email {
                    title
                  }
                  buttons {
                    link {
                      target
                      title
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default index
